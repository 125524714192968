import React from "react"

import Text from "@components/ui/Typography/Text"
import {
  CategoryInfo,
  QueryFilterCategory,
} from "@framework/types/query-filter"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import { camelCaseToWords, convertFirstLetterToUpper } from "@utils/textUtils"

import styles from "./ProductsSection.module.sass"

export interface FilterButtonProps {
  category: string
  count?: number
  name: string
  queryFilterCategory?: QueryFilterCategory
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<CategoryInfo | undefined>
  >
}

export const FilterButton: React.FC<FilterButtonProps> = ({
  category,
  count,
  name,
  queryFilterCategory,
  setSelectedCategory,
}) => {
  let heading = name
  if (category === "data") {
    heading = name.replace(/^data/, "content")
  }
  return (
    <Button
      after={<Icon name="line-arrow-down" rotateAngle={-90} />}
      variant="text"
      size="small"
      onClick={() =>
        setSelectedCategory({
          parentCategory: category,
          name,
          queryFilterCategory,
        })
      }
      className={styles.category}
    >
      <Text variant="body2" className={styles.name}>
        {convertFirstLetterToUpper(camelCaseToWords(heading))}&nbsp;
        <Text variant="body2" className={styles.count}>
          ({count})
        </Text>
      </Text>
    </Button>
  )
}
