import React from "react"

import Icon from "@components/ui/Icon/Icon"
import { SolutionData } from "@framework/types/solution"
import Button from "@components/ui/Button/Button"
import { convertFirstLetterToUpper } from "@utils/textUtils"
import Text from "@components/ui/Typography/Text"
import { getSolutionCoverImage } from "@framework/constants/search-results"
import { AppMediaQueries } from "@framework/constants/app"
import useMediaQuery from "@components/hooks/useMediaQuery"

import styles from "./SolutionCard.module.sass"

type SolutionCardProps = {
  solution: SolutionData
  onClick?: (value: SolutionData) => void
}

const SolutionCard: React.FC<SolutionCardProps> = ({ solution, onClick }) => {
  const name = convertFirstLetterToUpper(solution.name)
  const description = convertFirstLetterToUpper(solution.description)
  const coverImage = getSolutionCoverImage(solution.key)
  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)

  return (
    <button
      className={styles.root}
      type="button"
      onClick={onClick ? () => onClick(solution) : undefined}
    >
      <img
        className={styles.illustration}
        src={solution.image || coverImage}
        alt={name}
      />

      <div className={styles.body}>
        <div className={styles.title} title={name}>
          {name}
        </div>

        <div className={styles.description}>{description}</div>

        <div className={styles.cta}>
          <Button
            size={isTablet ? "small" : "medium"}
            variant="text"
            after={<Icon name="arrow-up" rotateAngle={90} />}
          >
            <Text variant="body1">{solution.workflowType}</Text>
          </Button>
        </div>
      </div>
    </button>
  )
}

export default SolutionCard
