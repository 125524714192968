import { AppRestrictions } from "../types/app"

const DEFAULT_RESTRICTION_VALUE = false

export const defaultAppRestrictions: AppRestrictions = {
  showChannels: true,
  showAnswerFeedback: DEFAULT_RESTRICTION_VALUE,
  showDataExplorer: true,
  twoFactorAuth: DEFAULT_RESTRICTION_VALUE,
  showTableScreenShot: DEFAULT_RESTRICTION_VALUE,
  showLatestUpdates: DEFAULT_RESTRICTION_VALUE,

  isSSOEnabled: false,
  enableSAMLSSO: false,

  showSortModifiedDateOption: false,

  disableSearchExtendedView: false,

  disableInformalKnowledgeSection: false,
  disableExpertKnowledgeSection: false,

  showSummaryAIAlert: false,

  showGlossary: true,
  enableGlossaryUpload: false,

  enableVoiceRecognizer: true,
}

export const AppMediaQueries = {
  minMobile: "(max-width: 465px)",
  maxMobile: "(max-width: 768px)",
  minTablet: "(max-width: 900px)",
  maxTablet: "(max-width: 1100px)",
  minDesktop: "(max-width: 1200px)",
  maxDesktop: "(max-width: 1450px)",
  wideDesktop: "(max-width: 1700px)",
}
