import React, { useCallback, useState } from "react"
import clsx from "clsx"
import debounce from "lodash/debounce"
import { observer } from "mobx-react-lite"

import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import { useController, useStore } from "@store"
import { convertFirstLetterToUpper, removeHyphens } from "@utils/textUtils"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import Button from "@components/ui/Button/Button"

import styles from "./Header.module.sass"

const Header: React.FC = observer(() => {
  const [search, setSearch] = useState<string>("")
  const { solutionsController } = useController()
  const { solutionsStore } = useStore()
  const { checkAndApplyFilter, searchFilters, removeFilter, clearAllFilters } =
    solutionsController
  const { searchLoading, searchedFilters, filtersToApply } = solutionsStore

  const debouncedSearch = useCallback(
    debounce((query) => {
      searchFilters(query)
    }, 300),
    []
  )

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearch(value)
    debouncedSearch(value)
  }

  return (
    <div className={styles.header}>
      <div className={styles.headingWrapper}>
        <Text variant="h3">Filters</Text>
        <Button size="tiny" variant="text" onClick={clearAllFilters}>
          Clear All
        </Button>
      </div>

      <TextInput
        className={styles.searchInput}
        placeholder="What are you looking for..."
        before={<Icon name="search" />}
        autoFocus
        value={search}
        after={
          search ? <Icon onClick={() => setSearch("")} name="cross" /> : null
        }
        onChange={handleSearchChange}
      />
      {search && (
        <div className={styles.filters}>
          {searchLoading && (
            <Skeleton count={3} lineHeight={30} minWidth={100} />
          )}
          {!searchLoading && (
            <>
              {!searchedFilters?.length && (
                <Text variant="body2">No search result</Text>
              )}
              {searchedFilters.map((item) => (
                <CheckboxWithLabel
                  className={clsx(styles.checkboxFilterName)}
                  onCheck={() => checkAndApplyFilter(item)}
                  label={convertFirstLetterToUpper(removeHyphens(item.name))}
                  checked={
                    !!filtersToApply.find(
                      (curFilter) => curFilter.id === item.id
                    )
                  }
                />
              ))}
            </>
          )}
        </div>
      )}
      <div className={styles.mobileSelectedFilter}>
        {filtersToApply.map((item) => (
          <div>
            {item.name} <Icon onClick={() => removeFilter(item)} name="cross" />
          </div>
        ))}
      </div>
    </div>
  )
})
export default Header
